/*
  Main JS
*/

$(function () {
	// Key visual

	OLEDUI.KvSwiper = function (t) {
		const swipeCont = t;
		const $btnPause = $(swipeCont).find(".btn-pause");
		const $btnPlay = $(swipeCont).find(".btn-play");
		let $desktopActiveVideo = null;
		let $mobileActiveVideo = null;
		// const $btnPrev = $(swipeCont).find(".swiper-button-prev");
		// const $btnNext = $(swipeCont).find(".swiper-button-next");

		let isPaused = false;
		let remainingTime = 0;
		let kvAutoPlay = { delay: 5000, disableOnInteraction: false };

		if ($(swipeCont).find(".swiper-slide ").eq(0).hasClass("video-slide")) {
			kvAutoPlay = false;
		}

		const KvSwiper = new Swiper(t, {
			pagination: {
				el: ".swiper-pagination",
				// renderBullet: function (index, className) {
				// 	return (
				// 		'<span class="' +
				// 		className +
				// 		'"><span class="progress"></span><span class="sr-only">' +
				// 		(index + 1) +
				// 		"</span></span>"
				// 	);
				// },
				clickable: true,
				renderBullet: function (index, className) {
					return (
						'<span class="' +
						className +
						'" role="button" aria-label="Go to slide ' +
						(index + 1) +
						'"><span class="progress"></span></span>'
					);
				},
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			loop: true,
			speed: 500,
			autoplay: kvAutoPlay,
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			noSwiping: true,
			// preloadImages: true,
			allowTouchMove: true,
			// lazy: true,
			lazy: {
				loadPrevNext: true,
			},
			breakpoints: {
				// min 750px
				751: {
					speed: 1000,
					allowTouchMove: false,
				},
			},
			on: {
				init: function () {
					// const videos = swiper.el.querySelectorAll(".image-area .inner.mobile .video");
					// videos.forEach((video, index) => {
					// 	let id = null;
					// 	const interval = (timestamp) => {
					// 		if (video.paused === false) {
					// 			video.removeAttribute("style");
					// 			video.style.objectFit = "contain";
					// 			return cancelAnimationFrame(id);
					// 		}
					// 		id = requestAnimationFrame(interval);
					// 	};
					// 	interval();
					// });
				},
			},
		});

		function videoPlayAfterLoad() {
			let video = document.querySelector(".key-visual .swiper-slide-active .inner.mobile .video");
			if (window.innerWidth > 750) {
				video = document.querySelector(".key-visual .swiper-slide-active .inner.desktop .video");
			}
			if (video) {
				const loadedVideo = function () {
					// $(".logo").css("color", "white").html(video.readyState);
					if (video.readyState >= 2) {
						$(".key-visual video").removeAttr("style");
						$btnPlay.click();
						KvSwiper.params.autoplay.delay = 5500;
						KvSwiper.params.autoplay.disableOnInteraction = false;
						KvSwiper.params.autoplay.enabled = true;
						KvSwiper.autoplay.run();
						startProgressAnimation();
					} else {
						setTimeout(function () {
							loadedVideo();
						}, 500);
					}
				};
				// $(".logo").css("color", "white").html(video.readyState);
				video.addEventListener("loadeddata", function () {
					loadedVideo();
				});
				video.load();
			}
		}
		videoPlayAfterLoad();

		let progressInterval; // 전역 변수로 선언

		function startProgressAnimation() {
			const progressEl = $(swipeCont).find(".swiper-pagination-bullet-active .progress");
			// console.log($(".swiper-pagination-bullet-active .progress"));
			progressEl.css("transform", "scaleX(0)");
			const duration = KvSwiper.params.autoplay.delay || 6000;
			const increment = 10 / duration; // 10ms마다 (1/duration) 만큼 증가하도록 변경
			let progress = 0;
			progressInterval = setInterval(() => {
				if (!isPaused) {
					progress += increment;
					if (progress >= 1) {
						progress = 1;
						clearInterval(progressInterval);
						// 만약 일시 정지된 시간이 있다면, 해당 시간만큼 재생 후 다음 슬라이드로 이동
						if (remainingTime > 0) {
							KvSwiper.slideNext();
							remainingTime = 0; // 일시 정지된 시간 초기화
						}
					}
					progressEl.css("transform", "scaleX(" + progress + ")");
				}
			}, 10);
		}

		function resetProgressAnimation() {
			clearInterval(progressInterval);
		}

		// page load
		const $activePaging = $(swipeCont).find(".swiper-pagination-bullet-active");
		setTimeout(function () {
			$activePaging.addClass("on");
		}, 10);
		if (!!kvAutoPlay) startProgressAnimation();

		/**
		 * @description 
		 * gt.yang 240102 : 아래 문제로 대폭 수정
		 * 
		 * 문제
		 * 1. slideChage 이벤트는 슬라이드들이 복제된 경우 등, 마지막 슬라이드와 첫슬라이드에서 2번 발생하고 발생할 때 마다 index 관련 수치가가 달라 컨트롤하기 복잡함.
		 * 2. 운영서버에서도 3번째 영상과 4번째 이미지 슬라이드 사이를 번갈아 이동시 영상 초기화 안되는 문제.
		 * 3. 첫번째 슬라이드와 마지막 슬라이드를 번갈아 이동시, 슬라이드 이동 과정에서 이전 영상이 초기화 됨.
		 * 
		 * 해결 방안
		 * 1. slidechange 대신 slideChangeTransitionStart를 활용
		 * 2. 슬라이드가 보이는 조건 제거 및 복제된 같은 슬라이드도 동일하게 처리
		 */
		/* KvSwiper.on("slideChangeTransitionStart", function () {
			console.log('slideChangeTransitionStart', );
			
		}) */
		KvSwiper.on("slideChangeTransitionEnd", function () {
			// console.log('this.realIndex', this.realIndex);
			// console.log('this.snapIndex', this.snapIndex);
			// console.log('this.previousIndex', this.previousIndex);

			this.slides.forEach(element => {
				if(element.classList.contains('video-slide') === true) {
					if(+element.getAttribute('data-swiper-slide-index') !== this.realIndex) { // 활성화된 슬라이드를 제외한 모든 슬라이드
						element.querySelectorAll('video').forEach(video => {
							video.pause();
							video.currentTime = 0;
						});
					}
				}
			});
		})
		KvSwiper.on("slideChangeTransitionStart", function () {
			const $activePaging = $(swipeCont).find(".swiper-pagination-bullet-active");
			let $activeSlide = $(swipeCont).find(".swiper-slide-active");
			$activePaging.siblings().removeClass("on");
			$activePaging.addClass("on");
			resetProgressAnimation();
			startProgressAnimation();
			const $siblingsDesktopVideo = $activeSlide.siblings().find(".desktop video");
			const $siblingsMobileVideo = $activeSlide.siblings().find(".mobile video");
			const $desktopVideo = $(swipeCont).find(".desktop video");
			const $mobileVideo = $(swipeCont).find(".mobile video");
			if ($desktopVideo.is(":visible") && $siblingsDesktopVideo.length > 0) {
				setTimeout(function () {
					let $activeSlide = $(swipeCont).find(".swiper-slide-active, .swiper-slide-duplicate-active");
					const $activeDesktopVideo = $activeSlide.find(".desktop video");
					if (!isPaused) {
						$activeDesktopVideo.each(function() {
							this.play();
						});
					}
				}, 10);
			}
			if ($mobileVideo.is(":visible") && $siblingsMobileVideo.length > 0) {
				setTimeout(function () {
					let $activeSlide = $(swipeCont).find(".swiper-slide-active, .swiper-slide-duplicate-active");
					const $activeMobileVideo = $activeSlide.find(".mobile video");
					if (!isPaused) {
						$activeMobileVideo.each(function() {
							this.play();
						});
					}
				}, 10);
			}
		});

		function kvPause() {
			isPaused = true;
			KvSwiper.autoplay.stop();
			$btnPause.removeClass("active");
			$btnPlay.addClass("active");
			remainingTime = (KvSwiper.params.autoplay.delay || 6000) * (1 - getProgress());
		}
		function kvPlay() {
			isPaused = false;
			KvSwiper.autoplay.start();
			$btnPause.addClass("active");
			$btnPlay.removeClass("active");
		}
		function getProgress() {
			const $activePaging = $(swipeCont).find(".swiper-pagination-bullet-active");
			const progressEl = $activePaging.find(".progress");
			const progress = parseFloat(progressEl.css("transform").split("(")[1].split(")")[0]);
			return progress || 0;
		}

		$btnPause.on("click", function () {
			kvPause();
			$desktopActiveVideo = $(swipeCont).find(".swiper-slide-active .desktop video, .swiper-slide-duplicate-active .desktop video");
			$mobileActiveVideo = $(swipeCont).find(".swiper-slide-active .mobile video, .swiper-slide-duplicate-active .mobile video");
			$btnPause.addClass("clicked");
			$btnPlay.removeClass("clicked");
			console.log('$desktopActiveVideo', $desktopActiveVideo);
			if (window.innerWidth > 750) {
				$desktopActiveVideo.each(function() { // 데스크탑 버전 비디오 일시정지
					this.pause();
				});
			}

			if (window.innerWidth <= 750) {
				$mobileActiveVideo.each(function() { // 모바일 버전 비디오 일시정지
					this.pause();
				});
			}
		});
		$btnPlay.on("click", function () {
			const $activePaging = $(swipeCont).find(".swiper-pagination-bullet-active");
			$desktopActiveVideo = $(swipeCont).find(".swiper-slide-active .desktop video, .swiper-slide-duplicate-active .desktop video");
			$mobileActiveVideo = $(swipeCont).find(".swiper-slide-active .mobile video, .swiper-slide-duplicate-active .mobile video");
			$activePaging.removeClass("on");
			// $(swipeCont).find(".full").removeClass("full");
			kvPlay();
			$btnPlay.addClass("clicked");
			$btnPause.removeClass("clicked");
			setTimeout(function () {
				$activePaging.addClass("on");
			}, 100);
			if ($(window).width() <= 750) {
				$activePaging.addClass("on");
			}
			if (window.innerWidth > 750) {
				$desktopActiveVideo.each(function() { // 데스크탑 버전 비디오 재생
					this.play();
				});
			}

			if (window.innerWidth <= 750) {
				$mobileActiveVideo.each(function() { // 모바일 버전 비디오 재생
					this.play();
				});
			}
		});
		$(".swiper-pagination-bullet").on("click", function () {
			setTimeout(function () {
				const $activePaging = $(swipeCont).find(".swiper-pagination-bullet-active");
				$activePaging.removeClass("on");
				if ($btnPlay.hasClass("clicked") || $btnPause.hasClass("active")) {
					kvPlay();
				} else {
					kvPause();
				}
			}, 100);
			if ($(window).width() <= 750) {
				const $activePaging = $(swipeCont).find(".swiper-pagination-bullet-active");
				$activePaging.removeClass("on");
				if ($btnPlay.hasClass("clicked") || $btnPause.hasClass("active")) {
					kvPlay();
				} else {
					kvPause();
				}
			}
		});
	};
	if ($(".main-wrap .key-visual").length > 0) {
		const mainKv = $(".key-visual");
		OLEDUI.KvSwiper(".key-visual");
		mainKv.addClass("swiper-load");

		const kvEl = document.querySelector(".main-kv");

		const fixKVHeight = function () {
			kvEl.style.height = "auto";
			if ($(window).width() <= 750) {
				kvEl.querySelector(".swiper-wrapper").style.height = `${window.innerHeight - 69}px`;
				setTimeout(function () {
					kvEl.querySelector(".swiper-wrapper").style.height = `${window.innerHeight - 69}px`;
				}, 500);
				setTimeout(function () {
					kvEl.querySelector(".swiper-wrapper").style.height = `${window.innerHeight - 69}px`;
				}, 800);
			} else {
				kvEl.querySelector(".swiper-wrapper").style.height = "auto";
				setTimeout(function () {
					kvEl.querySelector(".swiper-wrapper").style.height = "auto";
				}, 800);
			}
		};

		$(window).on("resize", fixKVHeight).resize();

		// const kvHeight = kvEl.offsetHeight;
		// const imgEl = document.querySelectorAll(".main-kv .image-area");
		const txtEl = document.querySelectorAll(".main-kv .floating-area .text-box");
		// const dimEl = document.querySelectorAll(".main-kv .dimmed");
		// const mainKvAni = function () {
		// 	let sc = $(window).scrollTop();
		// 	imgEl.forEach(function (item) {
		// 		if (window.matchMedia("(min-width: 751px)").matches) {
		// 			// desktop
		// 			// item.style.marginTop = sc/5 + 'px';
		// 			item.style.transform = "translate3d(0, " + sc / 5 + "px" + ", 0)";
		// 		} else {
		// 			// mobile
		// 			item.style.transform = "translate3d(0, " + sc / 10 + "px" + ", 0)";
		// 			// item.style.marginTop = sc/10 + 'px';
		// 		}
		// 	});
		// 	txtEl.forEach(function (item) {
		// 		if (window.matchMedia("(min-width: 751px)").matches) {
		// 			item.style.transform = "translate3d(0, " + -sc / 30 + "px" + ", 0)";
		// 		} else {
		// 			item.style.transform = "translate3d(0, " + -sc / 20 + "px" + ", 0)";
		// 		}
		// 	});
		// 	let opq = sc / $(window).height();
		// 	if (opq > 1) opq = 1;
		// 	dimEl.forEach(function (item) {
		// 		item.style.opacity = opq;
		// 	});
		// };
		// $(window).on("scroll", function () {
		// 	let sc = $(window).scrollTop();
		// 	if (sc < kvHeight) {
		// 		mainKvAni();
		// 	}
		// });
		// $(window).on("resize", function () {
		// 	mainKvAni();
		// });

		$(txtEl)
			.find(".cta-wrap a")
			.on("click", function () {
				pushDataLayer({
					event: "click_button",
					click_button: {
						buttonText: convertTitle($(this).text()),
						sectionName: "key-visual-area",
						sectionContentTitle: convertTitle($(this).closest(".text-box").find(".title").text()),
						sectionContentSub: convertTitle($(this).closest(".text-box").find(".desc").text()),
						clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
					},
				});
			});

		$(kvEl)
			.find(".swiper-slide > a")
			.on("click", function () {
				const $link = $(this).parent().find(".cta-wrap a");
				pushDataLayer({
					event: "click_button",
					click_button: {
						buttonText: convertTitle($link.text()),
						sectionName: "key-visual-area",
						sectionContentTitle: convertTitle($link.closest(".text-box").find(".title").text()),
						sectionContentSub: convertTitle($link.closest(".text-box").find(".desc").text()),
						clickURL: $link.attr("href") == "#" ? "" : $link.attr("href"),
					},
				});
			});
	}

	// About OLED
	if ($(".main-wrap .about-oled").length > 0) {
		const $aboutOled = $(".main-wrap .about-oled");
		$aboutOled.find("a").on("click", function () {
			pushDataLayer({
				event: "click_button",
				click_button: {
					buttonText: convertTitle($(this).find(".btn").text()),
					sectionName: convertTitle($(this).find(".title").text()),
					sectionContentTitle: convertTitle($(this).find(".desc").text()),
					sectionContentSub: "",
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		// 	let inScreen = true;
		// 	$(window).on("scroll", function () {
		// 		const $this= $aboutOled.find(".image-area");
		// 		let t = $this.offset().top;
		//         let b = $this.offset().top + $this.height();
		// 		const sTop = $(window).scrollTop();
		// 		// animation
		// 		if (OLEDUI.isInScreen(t, b)) {
		// 			$aboutOled.addClass("on");
		// 		}
		// 	});
	}

	// Products
	if ($(".main-wrap .products").length > 0) {
		const $mainProducts = $(".main-wrap .products");
		$mainProducts.find(".products-item a").on("click", function () {
			pushDataLayer({
				event: "click_product",
				click_product: {
					productTitle: convertTitle($(this).find(".title").text()),
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		// 	$(window).on("scroll", function () {
		// 		// let $item = $mainProducts.find(".products-item");
		// 		let tt = $mainProducts.offset().top;
		// 		let tb = $mainProducts.offset().top + $mainProducts.height();
		// 		// animation
		// 		if (OLEDUI.isInScreen(tt, tb)) {
		// 			$mainProducts.addClass("on");
		// 		}
		// 		// $item.each(function () {
		// 		// 	let $this = $(this);
		// 		// 	let t = $this.offset().top;
		// 		// 	let b = $this.offset().top + $this.height();
		// 		// 	if (OLEDUI.isInScreen(t, b)) {
		// 		// 		OLEDUI.watchTimer($this);
		// 		// 	}
		// 		// });
		// 	});
	}

	// OLED Inside
	if ($(".main-wrap .inside").length > 0) {
		const $mainInside = $(".main-wrap .inside");
		$mainInside.find("a").on("click", function () {
			pushDataLayer({
				event: "click_article",
				click_article: {
					articleTitle: convertTitle($(this).find(".title").text()),
					clickSection: "",
					articleCategory: convertTitle($mainInside.find(".main-title .title").text()),
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		// 	$(window).on("scroll", function () {
		// 		let $item = $mainInside.find(".inside-item");
		// 		let st = $mainInside.height() / 2;
		// 		let tt = $mainInside.offset().top;
		// 		let tb = $mainInside.offset().top + $mainInside.height();

		// 		if (OLEDUI.isInScreen(tt, tb)) {
		// 			$mainInside.addClass("on");
		// 		}
		// 		$item.each(function () {
		// 			let $this = $(this);
		// 			let t = $this.offset().top;
		// 			let b = $this.offset().top + $this.height();
		// 			if (OLEDUI.isInScreen(t, b)) {
		// 				OLEDUI.watchTimer($this);
		// 			}
		// 		});
		// 	});
	}

	// Instagram Feed
	OLEDUI.instaSwiper = function (t) {
		const swipeCont = t;
		const $pageWrap = $(swipeCont).find(".swiper-pagination");
		const $pageActive = $(swipeCont).find(".swiper-pagination-active");
		const evolutionSwiper = new Swiper(t, {
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			loop: false,
			speed: 500,
			autoplay: false,
			centeredSlides: false,
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			preloadImages: false,
			allowTouchMove: true,
			lazy: false,
			// lazy: {
			// 	loadPrevNext: true,
			// },
			slidesPerView: "1",
			spaceBetween: 0,
			breakpoints: {
				// min
				751: {
					slidesPerView: "4",
					slidesPerGroup: 4,
					allowTouchMove: false,
				},
			},
			on: {
				activeIndexChange: function () {
					const pageAni = function () {
						const $bulletActive = $(swipeCont).find(".swiper-pagination-bullet-active");
						const posX = $bulletActive.offset().left - $pageWrap.offset().left;
						$pageActive.css("left", posX);
					};
					const pageAniAction = function () {
						setTimeout(() => {
							pageAni();
							$(window).on("resize", function () {
								pageAni();
							});
						}, 100);
					};
					pageAniAction();
					$(window).on("resize", function () {
						pageAniAction();
					});

					pushDataLayer({
						event: "swipe_gallery",
						swipe_gallery: {
							galleryTitle: convertTitle(
								$(swipeCont).closest(".insta-feed ").find(".main-title .title").text()
							),
						},
					});
				},
			},
		});
	};
	if ($(".main-wrap .insta-feed").length > 0) {
		const $instaFeed = $(".main-wrap .insta-feed");

		OLEDUI.instaSwiper(".insta-swiper");

		const setBullet = function () {
			setTimeout(function () {
				const $pageBullet = $instaFeed.find(".swiper-pagination-bullet");
				const $pageActive = $instaFeed.find(".swiper-pagination-active");
				const leng = $pageBullet.length;
				const num = (100 / leng).toFixed(2);
				$pageActive.css("width", num + "%");
			}, 100);
		};
		setBullet();
		$(window).on("resize", function () {
			setBullet();
		});
		$instaFeed.find(".link-item").on("click", function () {
			let splitImgName = $(this).find(".image-area img").eq(0).attr("data-src").split("/");
			let imgName = splitImgName[splitImgName.length - 1]
				.replaceAll("-l.jpg", "")
				.replaceAll("-s.jpg", "")
				.replaceAll(".jpg", "");
			pushDataLayer({
				event: "click_image",
				click_image: {
					imageType: "instagram",
					imageName: imgName,
				},
			});
		});

		$instaFeed.find(".cta-wrap a").on("click", function () {
			pushDataLayer({
				event: "click_button",
				click_button: {
					buttonText: convertTitle($(this).text()),
					sectionName: convertTitle($instaFeed.find(".main-title .title").text()),
					sectionContentTitle: convertTitle($instaFeed.find(".main-title .desc").text()),
					sectionContentSub: "",
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});

		// let inScreen = true;
		// $(window).on("scroll", function () {
		// 	const $this= $(instaSwiperCont);
		// 	let st = $this.height() / 2;
		// 	let t = $this.offset().top;
		//     let b = $this.offset().top + $this.height();
		// 	// animation
		// 	if (OLEDUI.isInScreen(t, b)) {
		// 		if( inScreen == true ) {
		//             $instaFeed.addClass("on");
		//             inScreen = false;
		//         }
		// 	}
		// });
	}
});
